import React from "react";
import StorageService from './utils/StorageService'
import consts from './constants/consts'
import {sendEventToDataStream, setStreamData} from './utils/commonUtil'

const defaultState = {
  user: null,
  updateState: (key, value) => {
  }
}

const AppContext = React.createContext(defaultState);

class AppProvider extends React.Component {
  state = {
    user: null,
    sudokuData:null
  }

  updateState = (key, value) => {
    const stateObj = {};
    stateObj[key] = value;
    this.setState(stateObj);
    if (key === 'user') {
      StorageService.set(consts.USER_STORAGE_KEY, value);
      /*if (!value) {
        setStreamData({
          country: undefined,
          timezone: undefined,
          city: undefined,
          id: undefined,
          email: undefined
        });
        return;
      }
      setStreamData({
        country: value.country,
        timezone: value.timezone,
        city: value.city,
        id: value._id,
        email: value.email
      });
      sendEventToDataStream();*/
    }
  }

  render() {
    const {children} = this.props
    const {
      user,sudokuData
    } = this.state
    console.log(this.state,"state");
    return (
      <AppContext.Provider
        value={{
          user,
          updateState: this.updateState,
          sudokuData
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}

export default AppContext;

export {AppProvider}
