exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-auth-0-callback-js": () => import("./../../../src/pages/auth0-callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-auth-direct-js": () => import("./../../../src/pages/auth/direct.js" /* webpackChunkName: "component---src-pages-auth-direct-js" */),
  "component---src-pages-auth-forgotpass-js": () => import("./../../../src/pages/auth/forgotpass.js" /* webpackChunkName: "component---src-pages-auth-forgotpass-js" */),
  "component---src-pages-auth-old-js": () => import("./../../../src/pages/auth-old.js" /* webpackChunkName: "component---src-pages-auth-old-js" */),
  "component---src-pages-auth-signin-js": () => import("./../../../src/pages/auth/signin.js" /* webpackChunkName: "component---src-pages-auth-signin-js" */),
  "component---src-pages-auth-signup-js": () => import("./../../../src/pages/auth/signup.js" /* webpackChunkName: "component---src-pages-auth-signup-js" */),
  "component---src-pages-callback-js": () => import("./../../../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-chat-js": () => import("./../../../src/pages/chat.js" /* webpackChunkName: "component---src-pages-chat-js" */),
  "component---src-pages-games-sudoku-js": () => import("./../../../src/pages/games/sudoku.js" /* webpackChunkName: "component---src-pages-games-sudoku-js" */),
  "component---src-pages-games-sudoku-play-js": () => import("./../../../src/pages/games/sudoku/play.js" /* webpackChunkName: "component---src-pages-games-sudoku-play-js" */),
  "component---src-pages-intermediate-js": () => import("./../../../src/pages/intermediate.js" /* webpackChunkName: "component---src-pages-intermediate-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-meet-js": () => import("./../../../src/pages/meet.js" /* webpackChunkName: "component---src-pages-meet-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-waitlist-js": () => import("./../../../src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */)
}

